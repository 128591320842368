<template>
    <div class ="projects">
        <div class="py-4 container-fluid">       
            <h4 class="section-title h4">License</h4>
            <div class="span" v-if ="!promocode==''" >
                <h5 style="vertical-align: middle; text-align: center; margin-top:10px;">{{promocode}}</h5>
                <span class="span"></span>
                <div class="item2" style="cursor:pointer; margin-right: 10px; width: 200px;">
                    <CustomSelect v-if="code_types.length >0" 
                        v-model="selected_type"
                        :options=code_types
                        :default="selected_type"
                        class="select"
                    />
                </div>
                <span class="span"></span>
                <soft-button color="gored" variant="gradient" style=" margin-top: 10px;" class="center" v-on:click="Submit">Submit</soft-button>
            </div>

            
            <div class="app">
                <span class="span"></span>
            </div>

            <!-- <soft-button color="gored" variant="gradient" style=" margin-top: 10px;" class="center" v-on:click="Generate">Generate New Promocode</soft-button> -->
            <div class="app">
                <span class="span"></span>
            </div>
            <div class="d-flex">
                <div class="item2 px-3 mb-2 w-50 ms-2" style="cursor:pointer; margin-top: 5px; width: 200px;">
                    <CustomSelect v-if="code_types.length >0" 
                        v-model="selected_type"
                        :options=code_types
                        :default="selected_type"
                        class="select"
                    />
                </div>
                <input v-model="count" type="number" min="1" max="500" class="px-3 mb-2 form-control w-50 ms-2" placeholder="Count" >
                <input v-model="remark"  class="px-3 mb-2 form-control w-50  0 ms-2" placeholder="comment" value=remark>
                <button
                    id="btn-white"
                    class="px-3 mb-2 btn  w-50 ms-2"
                    :class="['bg-gradient-success']"
                    @click="Submit"
                >
                <i >Generate</i>
              
                </button>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2">
                <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Code</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Type</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Assigned</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Comment</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Action</th>
                                <th class="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="my-table"  :key="index"  v-for="(item, index) in lists">
                            <td>
                                <div class="d-flex px-2 py-1">
                                <div>
                                    <h6 class="mb-0 text-sm">{{item.code}}</h6>
                                </div>
                                </div>
                            </td>
                            <td>
                                <h6 class="mb-0 text-sm">{{code_types[item.type]}}</h6>
                            </td>
                            <td>
                                <div class="form-check form-switch ps-0">
                                    <input
                                        class="mt-1 form-check-input"
                                        :class="' ms-auto'"
                                        type="checkbox"
                                        id="navbarExperimental"
                                        :checked=item.assigned
                                        @change=onCheck(index,!item.assigned)
                                    />
                                </div>
                            </td>
                            <td>
                                <!-- <h6 class="mb-0 text-sm">{{item.remark}}</h6> -->
                                <input v-model="item.remark"  class="px-3 mb-2 form-control w-100 ms-2" placeholder="comment" value=item.remark>
                            </td>
                            <td class="align-middle">
                                <a
                                
                                @click="onUpdate(index)"
                                class="text-secondary font-weight-bold text-xs"
                                data-toggle="tooltip"
                                data-original-title="Edit user"
                                style="cursor: pointer; color:darkgreen !important"
                                >Upload</a
                                >
                                <i style="margin-left:20px;"/>
                                <a
                                @click="onDelete(item.code)"
                                class="text-secondary font-weight-bold text-xs"
                                data-toggle="tooltip"
                                data-original-title="Edit user"
                                style="cursor: pointer; color: #d91b5c !important;"
                                >Delete</a
                                >
                            </td>
                            <td class="align-middle">
                                
                            </td>
                            
                            </tr>
                        </tbody> 
                    </table> 
                </div>
            </div>
            <div class="app">
                <div style="width:100%;">
                    <b-pagination 
                        v-model="currentPage" 
                        pills :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="my-table"
                        align="center"
                    ></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API } from 'aws-amplify';
import SoftButton from "@/components/SoftButton.vue";
import CustomSelect from "@/components/CustomSelect.vue";
export default {
    name: 'Admin',
    components: {
     SoftButton,
     CustomSelect,
    },
    created:  function () 
    {
        (async ()=>
        {
            this.loading = true;
            await this.callLambda();
            this.loading = false;
        })();
        
    },
    watch: {
		loading: function (loading) {
			if (loading) 
			{
				this.loader = this.$loading.show({container: this.$refs.loadingContainer});
			} else 
			{
				this.loader.hide();
				this.loader = null;
			}
		}
	},
    data() {
        return {    
            loading: false,
            promocode: "",
            code_types: [],
            selected_type: null,
            code_list: [],
            fields: [
                { key: "code" },
                { key: "type" },
                { key: "assigned" },
                { key: "comment" },
                { key: "actions" }
            ],
            perPage: 20,
            currentPage:1,
            count:1,
            remark:"",
        };
    },
    computed: {
      rows() {
        return this.code_list.length
      },
      lists () {
        const items = this.code_list
        // Return just page of items needed
        return items.slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
        )
        },
    },
    methods: {
        async callLambda()
        {
            try{
                //var user = await Auth.currentAuthenticatedUser();
                const data = await API.get('rpgPromocode','/promocode');
                for(let c in data.items)
                {
                    this.code_types.push(c);
                }
                for(let c in data.items)
                {
                    this.code_types[data.items[c].ID] = (data.items[c].Name);
                }
                //const data = await API.get('rpgUserProfile','/profile');
                await this.listPromocodes();

                

                return data;
            }catch(err)
            {
                console.log('rpgPromocode error: ',err);
                this.loading = false;
            }
        },
        async listPromocodes()
        {
            let params = {
                'queryStringParameters':
                {
                    list: true
                }
            }
            const data2 = await API.get('rpgPromocode','/promocode', params);
            this.code_list = []
            for(let c in data2.items)
            {
                if(data2.items[c].assigned==undefined)
                    data2.items[c].assigned = false;
                if(data2.items[c].remark==undefined)
                     data2.items[c].remark = "";
                if(data2.items[c].date!=undefined)
                    data2.items[c].date = new Date(data2.items[c].date);
                else
                    data2.items[c].date = new Date("2003-06-27T08:11:45.304Z");
                this.code_list.push(data2.items[c]);
            }
            this.code_list.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.date) - new Date(a.date);
                });
            //console.log("rpgPromocode "+data2);
            this.loading = false;
        },
        Generate() 
        {
            //this.loading = true;
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) 
            {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
            // let params = {
            //     'queryStringParameters':
            //     {
            //         code: result
            //     }
            // }
            // const data2 = await API.get('rpgPromocode','/promocode', params);
            // if(data2.item)
            // {
            //     this.Generate();
            // }
            // //console.log(data2);
            // this.promocode = result;
            // //console.log(result);
            // this.loading = false;
        },
        async Submit()
        {
           
            this.loading = true;
            try{

                // var id = -1;
                // for(var s in this.code_types)
                // {
                //     //console.log(this.code_types[s])
                //     if(this.code_types[s] == this.selected_type)
                //     {
                //         id = s;
                //         break;
                //     }
                // }
                // let codes = [];
                // for(let i=0; i<this.count; i++)
                // {
                //     codes.push(this.Generate());
                // }
                // if(codes.length==0)
                // {
                //     this.loading = false;
                //     return;
                // }
                // console.log(id,codes);

                const myItem = {    
                    headers: { 
                        "Content-Type":"application/json"
                    },
                    body: {
                        "code": this.Generate()  
                    }
                }
                await API.post('rpgLicense','/generate', myItem);
                //await this.listPromocodes();
            }
            catch(error)
            {
                console.log(error);
               
                //this.$refs['my-modal'].show();
            }
            this.loading = false;
        },
        async onDelete(code)
        {
            //console.log(code);
            this.loading = true;
            try{
           
                const myItem = {    
                    headers: { 
                        "Content-Type":"application/json"
                    },
                    body: {
                        "key": code
                    }
                }


                await API.del('rpgPromocode','/promocode', myItem);
                // var ret = await API.del('rpgPromocode', `/promocode/${code}`, {});
                //console.log(ret);
               
                await this.listPromocodes();
            }
            catch(error)
            {
                console.log(error);
               
                //this.$refs['my-modal'].show();
            }
            this.loading = false;
        },
        async onUpdate(id)
        {
            id = (this.currentPage-1) * this.perPage +id
            this.loading = true;
            try{

               var item = this.code_list[id];
                const myItem = {    
                    headers: { 
                        "Content-Type":"application/json"
                    },
                    body: {
                        "code": item.code,
                        "type": item.type,
                        "assigned": item.assigned,
                        "remark": item.remark
                    }
                }
                await API.put('rpgPromocode','/promocode', myItem);
                
                await this.listPromocodes();
            }
            catch(error)
            {
                console.log(error);
               
                //this.$refs['my-modal'].show();
            }
            this.loading = false;
        },
        onCheck(id, checked)
        {
            id = (this.currentPage-1) * this.perPage +id
            this.code_list[id].assigned = checked;
            //console.log(id+ " "+checked+" "+ this.code_list[id].remark);
        },
        onComment(id,text)
        {
            id = (this.currentPage-1) * this.perPage +id
            console.log(id+" "+text);
        },
        async onTest(e, your_value)
        {
            e;
            your_value;
            //console.log(e, your_value);
            let el = this.$refs.comment._data.localValue;
           
            let params = {
                'queryStringParameters':
                {
                    code: el
                }
            }
            await API.get('rpgPromocode','/use',params);
            
            await this.listPromocodes();
        }
        
    }
}
</script>
<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  overflow-y: scroll;
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: -1;
  background: #eee !important;

}


.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}

section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007bff;
    margin-bottom: 50px;
    text-transform: uppercase;
}
div.ex1 {
  width: 300px;
  margin: auto;
  text-align: left;
  
}
.card {
   
    background: #ffffff;
    margin-bottom: 30px;
}
.span
{
    display:inline-flex;
    margin-right: 20px;
    align-items: center;
    text-align: center;
}

</style>