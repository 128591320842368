<template>
    <div class ="profile">
    
        <div class="py-4 container-fluid">
        <div
            class="mt-4 page-header min-height-100 border-radius-xl"
            :style="{
            backgroundImage:
                'url(' + require('@/assets/img/curved-images/white-curved.jpeg') + ')',
            backgroundPositionY: '50%',
            }"
        >
            <span class="mask bg-gradient-faded-gored opacity-8"><h5 class="mb-1" style="color:white; margin: 35px;">Pricing</h5> </span>
            
            
        </div>
        </div>
        <div class="row" >
        <div style="max-width:50%; min-width: 400px; margin-bottom: 10px; margin-left: auto; margin-right: auto;">
          <pricing-card v-if="license.length>0"
            title="License Information"
            :bills="license"
          />
        </div>
        <br>
        <div style="max-width:50%; min-width: 400px; margin-bottom: 10px; margin-left: auto; margin-right: auto;">
          <pricing-card v-if="credits.length>0"
            title="Credits Information"
            :bills="credits"
          />
        </div>
        <br>
        <div style="max-width:50%; min-width: 400px; margin-bottom: 10px; margin-left: auto; margin-right: auto;">
          <pricing-card v-if="plugins.length>0"
            title="Plugins Information"
            :bills="plugins"
          />
        </div>
        <br>
        <div style="max-width:50%; min-width: 400px; margin-bottom: 10px; margin-left: auto; margin-right: auto;">
          <pricing-card v-if="assets.length>0"
            title="Assets Information"
            :bills="assets"
          />
        </div>
        <br>
      </div>
    </div>
</template>

<script>
import PricingCard from "@/components/PricingCard.vue";
import { API } from 'aws-amplify';
export default {
  name: "Pricing",
  components: {
    PricingCard,
  },
  data()
  {
    return{
        // isTW: true
        license: [],
        credits: [],
        plugins: [],
        assets: [],
        loading: false,
    }
  },
  async mounted() {
    this.loading = true;
    const inventory = await API.get('rpgStoreInventory','/items');
    this.isTW = inventory.country==="TW";
    

    for(let f in inventory.items)
    {
        let item = inventory.items[f];
        if(item.Type ==="License" && item.Available)
            this.license.push({name:item.Name,description: 'License for RedPillGo Hub', price:this.isTW==true?item.PriceNT+" NT":item.Price+" USD", purcasable:true})
        else if(item.Type ==="Credit" && item.Available)
            this.credits.push({name:item.Name,description: 'Credits used for animation recording and uploading avatars', price:this.isTW==true?item.PriceNT+" NT":item.Price+" USD", purcasable:true})
    }
    for(let f in inventory.features)
    {
        let item = inventory.features[f];
        if(item.Type ==="Unity")
            this.plugins.push({name:item.Name,description: "Enable connection from Red Pill Go Hub to Unity", price:this.isTW==true?item.PriceNT+" NT":item.Price+" USD", purcasable:true})
        else if(item.Type ==="Unreal")
            this.plugins.push({name:item.Name,description: 'Enable connection from Red Pill Go Hub to Unreal', price:this.isTW==true?item.PriceNT+" NT":item.Price+" USD", purcasable:true})
        else if(item.Type ==="VCam")
            this.plugins.push({name:item.Name,description: 'Use Red Pill Go Hub as camera source in video conferencing and streaming apps', price:this.isTW==true?item.PriceNT+" NT":item.Price+" USD", purcasable:true})
        else if(item.Type =="Upload")
            this.assets.push({name:item.Name,description: 'Upload or create custom avatars to go.rplab.online', price:this.isTW==true?item.PriceNT+" credits":item.Price+" credits", purcasable:false})
        else if(item.Type =="Animation")
            this.assets.push({name:'3D Animation',description: 'Record and retarget performance stream from Red Pill Go Hub', price:this.isTW==true?item.PriceNT+ " credit / 5 sec":item.Price+ "credit / 5 sec", purcasable:false})
    }
    this.loading = false;
  },
  watch: {
		loading: function (loading) {
			if (loading) 
			{
				this.loader = this.$loading.show({container: this.$refs.loadingContainer});
			} else 
			{
				this.loader.hide();
				this.loader = null;
			}
		},
    }
  
}
</script>